import React from 'react';

import Namecheck from 'components/directus/namecheck/Namecheck';
import OrderSteps from 'components/directus/order-steps/OrderSteps';
import packagesHook from 'hooks/packages/packages.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './NameCheckPackages.interface';

import s from './NameCheckPackages.scss';

export default ({ slug }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, packagesHook());

    return (
        <>
            <OrderSteps currentStep={1} />
            <div className={s.namecheckContainer}>
                <h1 className={s.namecheckContainer__title}>
                    You have chosen the <span className={s.namecheckContainer__name}>{node.name} Package</span>.<br />
                    Now choose your company name.
                </h1>
                <Namecheck packageSlug={node.slug} />
            </div>
        </>
    );
};
