import React from 'react';

import { Props } from './NamecheckNotification.interface';

import s from './NamecheckNotification.scss';

export const NamecheckNotification = ({ notification, home, status }: Props): JSX.Element => {
    const { iconSrc, companyName, description } = notification;
    const styles = {
        home: home ? s.namecheckNotification___home : '',
        status: status ? s[ `namecheckNotification___${status.toLowerCase()}` ] : '',
    };

    return (
        <div
            className={`${s.namecheckNotification} ${styles.home} ${styles.status}`}
        >
            <img
                src={iconSrc}
                alt={`Icon for ${status.toLowerCase()} status.`}
                className={s.namecheckNotification__icon}
            />
            <h3 className={s.namecheckNotification__companyName}>{companyName}</h3>
            <div className={s.namecheckNotification__description}>{description}</div>
        </div>
    );
};
