import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusOrderStep, OrderStepsEdges } from './order-steps.interface';

export default (): OrderStepsEdges[] => {
    const { allDirectusOrderStep } = useStaticQuery<AllDirectusOrderStep>(graphql`
    query {
      allDirectusOrderStep(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            heading
            number
            icon
          }
        }
      }
    }
  `);

    return allDirectusOrderStep.edges;
};
