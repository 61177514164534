import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import orderStepHook from 'hooks/order-steps/order-steps.hook';
import { getRegularFontAwesomeIcon, getLightFontAwesomeIcon } from 'utils/font-awesome';
import { Props } from './OrderSteps.interface';

import s from './OrderSteps.scss';

export default ({ currentStep }: Props): JSX.Element => {
    const steps = orderStepHook();
    const activeClass = (step: string | number) => currentStep >= Number(step)
        ? s.orderSteps__step___active
        : '';

    const currentClass = (step: string | number) => currentStep === Number(step)
        ? s.orderSteps__step___current
        : '';

    const activeArrowClass = (step: string | number) => currentStep > Number(step)
        ? s.orderSteps__arrow___active
        : '';

    return (
        <>
            <div className={s.orderSteps}>
                {steps.map(({ node }) => (
                    <div
                        data-step={node.number}
                        className={`${s.orderSteps__step} ${activeClass(node.number)} ${currentClass(node.number)}`}
                        key={`order-step-${node.number}}`}
                    >
                        <div className={s.orderSteps__icon}>
                            <FontAwesomeIcon icon={getLightFontAwesomeIcon(node.icon)} />
                        </div>
                        <div className={s.orderSteps__content}>
                            <span className={s.orderSteps__number}>{node.number}.</span> {node.heading}
                        </div>

                        {Number(node.number) < 4 && (
                            <div className={`${s.orderSteps__arrow} ${activeArrowClass(node.number)}`}>
                                <FontAwesomeIcon icon={getRegularFontAwesomeIcon('faChevronRight')} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};
