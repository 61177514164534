const WIDTH_XS = 420;
const WIDTH_SM = 768;
const WIDTH_MD = 992;
const WIDTH_LG = 1200;

export {
    WIDTH_XS,
    WIDTH_SM,
    WIDTH_MD,
    WIDTH_LG,
};
