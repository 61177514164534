import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPackage, PackageEdges } from './packages.interface';

export default (): PackageEdges[] => {
    const { allDirectusPackage } = useStaticQuery<AllDirectusPackage>(graphql`
        query {
            allDirectusPackage {
                edges {
                    node {
                        directusId
                        slug
                        name
                        type
                        description
                        price {
                            value
                        }
                        discounted_price {
                            value
                        }
                        namecheck_path
                        checkout_path
                        icon
                        stacked_icon
                        skip_address_services
                    }
                }
            }
        }
    `);

    return allDirectusPackage.edges;
};
