import React from 'react';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { connect } from 'react-redux';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Button from 'components/common/button/Button';
import { NamecheckNotification } from 'components/directus/namecheck-notification/NamecheckNotification';
import namecheckHook from 'hooks/namecheck/namecheck.hook';
import windowResizeHook from 'hooks/resize/resize.hook';
import { checkCompanyName, efilingParser, errorNotification, emptyNotification } from 'services';
import { cookieOptions } from 'utils/cookie';
import { WIDTH_XS } from 'utils/pixel-widths';
import { url } from 'utils/url';
import { StoreProps } from 'store/reducers/namecheck/namecheck.interface';
import { Props } from './Namecheck.interface';

import s from './Namecheck.scss';

const Namecheck = ({ home, handleChange, packageSlug = '', state, dispatch }: Props): JSX.Element => {
    const [ cookies, setCookie, removeCookie ] = useCookies();
    const { placeholder_text, placeholder_text_mobile, continue_url } = namecheckHook();
    const isMobile = WIDTH_XS >= windowResizeHook().width;
    const isNonResidents = packageSlug !== '' && packageSlug.indexOf('non-residents') > -1;
    const { enteredName, submittedName, notification, isInProgress } = state;

    const resetState = (event: any): void => {
        event.preventDefault();
        removeCookie('company-name');

        dispatch({ type: 'CLEAR_ALL' });
        if (home) handleChange(true);
    };

    const updateEnteredName = (value: string) => {
        dispatch({ type: 'UPDATE_NAMECHECKENTERED', payload: value });
    };

    const handleSubmit = async (event: any): Promise<void> => {
        event.preventDefault();
        if (enteredName === '') {
            if (home) handleChange(false);
            dispatch({ type: 'UPDATE_NAMECHECKNOTIFICATION', payload: emptyNotification() });
        }
        if (enteredName === submittedName) return;

        try {
            dispatch({ type: 'UPDATE_NAMECHECKPROGRESS', payload: true });
            const response = await checkCompanyName(enteredName);
            const notification = efilingParser(response);
            if (home) handleChange(false);
            if (home && isMobile) scrollTo('#home-top');
            dispatch({ type: 'UPDATE_NAMECHECKNOTIFICATION', payload: notification });
            setCookie('company-name', notification.companyName, cookieOptions());
        } catch (e) {
            dispatch({ type: 'UPDATE_NAMECHECKNOTIFICATION', payload: errorNotification() });
        } finally {
            dispatch({ type: 'UPDATE_NAMECHECKENTERED', payload: '' });
            dispatch({ type: 'UPDATE_NAMECHECKPROGRESS', payload: false });
        }
    };

    if (notification) {
        if (notification.status === 'GREEN') {
            return (
                <div className={`${s.namecheck} ${home ? s.namecheck___home : ''}`}>
                    <NamecheckNotification
                        notification={notification}
                        home={home}
                        status={notification.status}
                    />

                    <div className={s.namecheck__success}>
                        { packageSlug !== '' ? (
                            <>
                                {isNonResidents ? (
                                    <Button
                                        to={url.account(`/buy/${packageSlug}?name=${cookies[ 'company-name' ]}&gle=namecheck`)}
                                        role="success"
                                        className={s.namecheck__button}
                                    >
                                        Save &amp; Continue
                                    </Button>
                                ) : (
                                    <Button
                                        to={`/address-services/?p=${packageSlug}`}
                                        role="success"
                                        className={s.namecheck__button}
                                    >
                                        Save &amp; Continue
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button
                                to={continue_url}
                                role="success"
                                className={s.namecheck__button}
                            >
                                Save &amp; Continue
                            </Button>
                        )}
                        <Button
                            role="secondary"
                            onClick={(e: any) => resetState(e)}
                            className={s.namecheck__reset}
                        >
                            Or search again
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className={`${s.namecheck} ${home ? s.namecheck___home : ''}`}>
                <NamecheckNotification
                    notification={notification}
                    home={home}
                    status={notification && notification.status}
                />

                <form onSubmit={e => handleSubmit(e)} className={s.namecheck__form}>
                    <div className={s.namecheck__search}>
                        <div className={s.namecheck__name}>
                            <input
                                type="text"
                                name="name"
                                placeholder={!isMobile ? placeholder_text : placeholder_text_mobile}
                                aria-label={!isMobile ? placeholder_text : placeholder_text_mobile}
                                autoComplete="off"
                                onChange={e => updateEnteredName(e.target.value)}
                                value={enteredName}
                                className={s.namecheck__input}
                                required={false}
                            />
                            { isInProgress && (
                                <FontAwesomeIcon className={s.namecheck__spinner} icon={faSpinner} spin />
                            )}
                        </div>
                        <Button
                            type="submit"
                            size="large"
                            role="primary"
                            className={`${s.namecheck__button} ${s.namecheck__button___alt}`}
                        >
                            Search again
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <div className={`${s.namecheck} ${home ? s.namecheck___home : ''}`}>
            <form onSubmit={e => handleSubmit(e)} className={s.namecheck__form}>
                <div className={s.namecheck__search}>
                    <div className={s.namecheck__name}>
                        <input
                            type="text"
                            name="name"
                            placeholder={!isMobile ? placeholder_text : placeholder_text_mobile}
                            aria-label={!isMobile ? placeholder_text : placeholder_text_mobile}
                            autoComplete="off"
                            onChange={e => updateEnteredName(e.target.value)}
                            value={enteredName}
                            className={s.namecheck__input}
                            required={false}
                        />
                        { isInProgress && (
                            <FontAwesomeIcon className={s.namecheck__spinner} icon={faSpinner} spin />
                        )}
                    </div>
                    <Button type="submit" size="large" role="primary" className={s.namecheck__button}>Search</Button>
                </div>
            </form>
        </div>
    );
};

export default connect((state: StoreProps) => ({
    state: { ...state.nameCheck },
}))(Namecheck);
